import React from "react";
import { Helmet } from "react-helmet"
import PublicationsPage from "../Components/PublicationsPage";


export default () => (
  <>
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
    >
      <title>Galaxy Therapeutics</title>
      <link rel="canonical" href="https://galaxytherapeutics.com" />
      
    </Helmet>
    <PublicationsPage />
  </>
);
