import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

import { cleanText } from './utils';

// core components
import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// import Button from "components/CustomButtons/Button.jsx"
import HeaderLinksExternal from "components/Header/HeaderLinksExternal.jsx"
import Parallax from "components/Parallax/Parallax.jsx"

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx"

// Sections for this page
import PublicationSection from "./Sections/PublicationSection.jsx"

const dashboardRoutes = []

class PublicationsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pubs: [] };
    this.queryPublications = this.queryPublications.bind(this);
  }

  queryPublications() {
    const route = "https://db.galaxytherapeutics.com/wp-json/wp/v2/publication";
    return fetch(route, {
      method: "GET"
    }).then(body => body.json());
  }

  componentDidMount() {
    this.queryPublications()
      .then(x => {
      // strip html tags and odd uri encoding
      x.forEach(el => {
        const { title: {rendered: title }, meta } = el;

        el.title.rendered = cleanText(title);
        Object.entries(meta).forEach(([key, val]) => {
          meta[key] = cleanText(val);
        });

      });

      return x
    }).then(pubs => this.setState({ pubs }));
  }

  render() {
    const { classes, ...rest } = this.props
    const { pubs } = this.state;

    return (
      <div>
        <Header
          color="white"
          routes={dashboardRoutes}
          brand="Galaxy Therapeutics"
          rightLinks={<HeaderLinksExternal />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white",
          }}
          {...rest}
        />
        <Parallax
          style={{ height: "400px" }}
          filter
          image={require("assets/img/galaxy_trees.jpg")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.title}>Publications</h1>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <PublicationSection 
              pubs={pubs}
            />
            <div style={{ height: "10px" }} />
          </div>
        </div>
        <div style={{ height: "10px" }} />
        <Footer />
      </div>
    )
  }
}

export default withStyles(landingPageStyle)(PublicationsPage)
