import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx"

const PublicationPost = props => {
  const { title, summary, author, journal, date, link } = props

  return (
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardBody>
          <h2
            style={{
              color: "black",
              // paddingLeft: "3rem",
              textAlign: "left",
              marginBottom: "10px",
            }}
          >
            {title}
          </h2>
          <h4
            style={{
              color: "black",
              padding: "0",
              textAlign: "left",
              marginTop: 0,
            }}
          >
            {author}
          </h4>
          <p
            style={{
              color: "black",
              // paddingLeft: "3rem",
              textAlign: "left",
            }}
          >
            {summary}
          </p>
          <p
            style={{
              // color: "black",
              // paddingLeft: "3rem",
              textAlign: "left",
            }}
          >
            <a href={link}>{journal}</a>
          </p>
        </CardBody>
      </Card>
    </GridItem>
  )
}

class PublicationSection extends React.PureComponent {
  render() {
    const { classes, pubs } = this.props;

    const publications = pubs
      .filter(el => el.status === "publish")
      .map(post => {
        const {
          id,
          title: { rendered: postTitle },
          meta: {
            p_journal: journal,
            p_author: author,
            p_abstract: summary,
            p_date: date,
            p_link: link,
          },
        } = post

        return (
          <PublicationPost
            key={id}
            title={postTitle}
            summary={summary}
            author={author}
            journal={journal}
            date={date}
            link={link}
          />
        )
      })


    return (
      <div className={classes.section} id="publications">
        <GridContainer justify="left">
          {publications}
        </GridContainer>
      </div>
    )
  }
}

export default withStyles(productStyle)(PublicationSection)
